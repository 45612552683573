import React from "react";
import { Text, View, TouchableOpacity } from "react-native-web";
import SunriseIcon from "../../../../common/components/SunriseIcon";

const TileSection = ({ section, navigation, inverted }) => {
  const { title, destination, colour, icon, button_text } = section;
  var button_colour = colour;
  var text_colour = "white";
  if (inverted === true) {
    button_colour = "white";
    text_colour = colour;
  }

  const {
    thumbnailStyle,
    headerContentStyle,
    thumbnailContainerStyle,
    headerTextStyle,
    containerStyle,
    iconContentStyle,
    iconStyle,
  } = styles;

  function selectNavigation() {
    if (section.page_link_type === "Wellness") {
      return navigation.navigate("Wellness");
    }
    if (section.page_link_type === "Timeline") {
      return navigation.navigate("Timeline");
    }
    if (section.page_link_type === "Normal") {
      return navigation.push("Page", { itemId: destination });
    } else {
      return navigation.push("Page", { itemId: destination });
    }
    //navigation.navigate('Page', { itemId: destination })
  }

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        padding: 11,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 8,
        backgroundColor: button_colour || "#0c9f96",
        justifyContent: "flex-start",
        position: "relative",
        alignItems: "stretch",
        borderRadius: 5,
      }}
      onPress={() => selectNavigation()}
    >
      <View style={thumbnailContainerStyle}>
        {icon === "none" ? (
          <View style={{ width: 20 }}></View>
        ) : (
          <SunriseIcon
            icon={icon}
            size={20}
            stroke={text_colour}
            fill={text_colour}
          />
        )}
      </View>
      <View style={headerContentStyle}>
        <Text style={{ color: text_colour, ...headerTextStyle }}>
          {section.button_text}
        </Text>
      </View>
      <View style={iconContentStyle}>
        <SunriseIcon icon={"arrow-right"} size={20} stroke={text_colour} />
      </View>
    </TouchableOpacity>
  );
};

const styles = {
  thumbnailContainerStyle: {
    flex: 0,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  thumbnailStyle: {
    height: 30,
    width: 30,
  },
  headerContentStyle: {
    flex: 1,
    //backgroundColor: "green",
    justifyContent: "space-around",
    //alignItems: "stretch",
  },
  headerTextStyle: {
    fontSize: 14,
    //fontFamily: "sansPro",
    textAlign: "center",
  },
  iconContentStyle: {
    marginLeft: 5,
    justifyContent: "space-around",
  },
  iconStyle: {
    width: 20,
  },
};

export default TileSection;
