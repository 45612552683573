import React from "react";
import { Text, View, Image, TouchableOpacity, Modal } from "react-native-web";
// import * as Linking from "expo-linking";
// import { WebView } from "react-native-webview";
// import * as WebBrowser from "expo-web-browser";
import SunriseIcon from "../../../../common/components/SunriseIcon";

import LoadingImage from "./video_loading.png";

export default class SectionWithExtLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model_visible: false,
      video_style: {
        height: this.props.settings.vimeo_height,
        width: this.props.settings.device_width,
        marginBottom: 20,
        position: "absolute",
      },
      image_style: {
        height: this.props.settings.vimeo_height,
        width: this.props.settings.device_width,
        marginBottom: 20,
      },
      video_height: this.props.settings.vimeo_height,
      video_buffer:
        (this.props.settings.device_height -
          ((this.props.settings.device_width - 20) / 3) * 1.8) /
        2,
    };
  }

  handleClick = () => {
    if (this.props.section.destination === null) {
      console.log("opening in web browser");
      //WebBrowser.openBrowserAsync(this.props.section.ext_link);
      return;
    }
    if (this.props.section.destination === "vimeo") {
      console.log("opening in vimeo popup");
      this.setState({ model_visible: true });
      return;
    }
    const link = this.props.section.destination + this.props.section.ext_link;
    //Linking.openURL(link);
  };

  renderModal() {
    if (this.props.section.destination === "vimeo") {
      const video_height = ((this.props.settings.device_width - 20) / 3) * 1.86;
      const video_buffer =
        (this.props.settings.device_height - video_height) / 2;
      return (
        <View>
          <Modal visible={this.state.model_visible} transparent={true}>
            <View style={{ backgroundColor: "#000000aa", flex: 1 }}>
              <TouchableOpacity
                onPress={() => this.setState({ model_visible: false })}
                style={{ height: video_buffer }}
              />
              <TouchableOpacity
                onPress={() => this.setState({ model_visible: false })}
                style={{ height: video_buffer }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    textAlign: "center",
                    flex: 0,
                    color: "white",
                    // fontFamily: "sansPro",
                  }}
                >
                  Close Video
                </Text>
              </TouchableOpacity>
            </View>
          </Modal>
        </View>
      );
    } else {
      return;
    }
  }

  onVimeoLoad = () => {
    this.setState({
      video_style: {
        height: this.state.video_height,
        marginBottom: 20,
        borderColor: "black",
      },
      image_style: { display: "none" },
    });
  };

  render() {
    const {
      title,
      destination,
      colour,
      icon,
      ext_link,
      image_url,
      image_hash,
      button_text,
    } = this.props.section;
    const {
      thumbnailStyle,
      headerContentStyle,
      thumbnailContainerStyle,
      headerTextStyle,
      containerStyle,
      iconContentStyle,
      iconStyle,
    } = styles;

    var button_colour = colour;
    var text_colour = "white";
    if (this.props.inverted === true) {
      button_colour = "white";
      text_colour = colour;
    }

    if (destination === "vimeo_embed") {
      var image = LoadingImage;
      console.log("image_url");
      console.log(image_url);
      if (image_url !== null) {
        console.log("we are in the function");
        image = { uri: image_url };
      }
      return (
        <View>
          <Image
            style={this.state.image_style}
            resizeMode="contain"
            source={image}
            key={"select_trust_logo"}
          />
          {/* <WebView
            source={{ uri: ext_link }}
            onLoadEnd={this.onVimeoLoad}
            style={this.state.video_style}
          /> */}
        </View>
      );
    } else {
      return (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            padding: 11,
            marginLeft: 12,
            marginRight: 12,
            marginBottom: 8,
            backgroundColor: button_colour || "#0c9f96",
            justifyContent: "flex-start",
            position: "relative",
            alignItems: "stretch",
            borderRadius: 5,
          }}
          onPress={() => this.handleClick()}
        >
          <View style={thumbnailContainerStyle}>
            {icon === "none" ? (
              <View style={{ width: 20 }}></View>
            ) : (
              <SunriseIcon
                icon={icon}
                size={20}
                stroke={text_colour}
                fill={text_colour}
              />
            )}
          </View>
          <View style={headerContentStyle}>
            <Text style={{ color: text_colour, ...headerTextStyle }}>
              {button_text}
            </Text>
          </View>
          <View style={iconContentStyle}>
            <SunriseIcon icon={"arrow-right"} size={20} stroke={text_colour} />
          </View>
          {this.renderModal()}
        </TouchableOpacity>
      );
    }
  }
}

const styles = {
  thumbnailContainerStyle: {
    flex: 0,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  thumbnailStyle: {
    height: 30,
    width: 30,
  },
  headerContentStyle: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "stretch",
  },
  headerTextStyle: {
    fontSize: 20,
    // fontFamily: "sansPro",
    textAlign: "center",
  },
  iconContentStyle: {
    flex: 0,
    marginLeft: 5,
    justifyContent: "space-around",
  },
  iconStyle: {
    width: 20,
  },
};
