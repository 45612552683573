import React from "react";
import { Text, View } from "react-native-web";
// import { FontAwesome } from '@expo/vector-icons';
//import { Title } from "../index";
import SunriseIcon from "../../../../common/components/SunriseIcon";
import SectionWithExtLink from "./SectionWithExtLink";
import SectionWithLink from "./SectionWithLink";
import SectionWithImage from "./SectionWithImage";

const SectionWithCheckbox = ({ section, font_size, settings }) => {
  const { title, destination, colour, icon, text, data } = section;

  const Title = ({ children }) => {
    return <div style={{ color: "#0c9f96", fontSize: 18 }}>{children}</div>;
  };

  function renderTop() {
    if (section.data === "external" && section.destination === "vimeo_embed") {
      var new_settings = { ...settings };
      new_settings.device_width = new_settings.device_width - 32;
      var borderRadius = 12;
      return (
        <div>
          <div style={{ width: 20 }} className="vimeo_embed">
            <iframe
              width={"240px"}
              title="section."
              src={section.ext_link}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      );
    }
    if (section.image_url !== null) {
      var new_settings = { ...settings };
      new_settings.device_width = settings.device_width - 50;
      return (
        <>
          <SectionWithImage
            width={100}
            section={section}
            settings={new_settings}
            // images={images}
            key={section.section_id}
            radius={true}
          />
        </>
      );
    }

    return;
  }

  function renderBottom() {
    if (section.data === "external" && section.destination !== "vimeo_embed") {
      // var new_settings = { ...settings };
      // new_settings.device_width = new_settings.device_width - 32;
      // var borderRadius = 12;
      return (
        <View>
          {/* <Text>{JSON.stringify(this.props.settings)}</Text> */}
          <SectionWithExtLink
            //borderRadius={borderRadius}
            section={section}
            settings={settings}
            key={section.section_id}
          ></SectionWithExtLink>
        </View>
      );
    }
    if (section.data === "internal") {
      return (
        <SectionWithLink
          section={section}
          // navigation={navigation}
          key={section.id}
        ></SectionWithLink>
      );
    }
    // if (section.image_url !== null) {
    //   return (
    //     <>
    //       <SectionWithImage
    //         width={settings.device_width - 30}
    //         section={section}
    //         images={images}
    //         key={section.section_id}
    //         radius={true}
    //       />
    //     </>
    //   );
    // }

    return;
  }

  let check_icon = "check";
  var fontColor = "#14A9A2";
  var fillColor = "#14A9A2";
  var strokeColor = "#fff";
  var borderColor = "#14A9A2";
  return (
    <View
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        borderRadius: 12,
        backgroundColor: "#F5F5F5",
        paddingBottom: 25,
        // paddingTop: 20,
        borderWidth: 2,
        overflow: "hidden",
        borderColor: "#14A9A2",
      }}
    >
      <View style={{ padding: 11 }}>
        <View style={{ padding: 15 }}>
          <Title align="left">{section.title}</Title>
        </View>
        {renderTop()}
        <div>
          <div
            className="section_text"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
        {renderBottom()}
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginTop: 25,
            marginBottom: 20,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <SunriseIcon
            icon={check_icon}
            size={45}
            stroke={strokeColor}
            fill={fillColor}
          />
          <Text
            style={{
              fontSize: 14,
              //fontFamily: "sansPro-semiBold",
              marginRight: 30,
              marginLeft: 20,
              color: fontColor,
            }}
          >
            {section.checkbox_text}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default SectionWithCheckbox;
