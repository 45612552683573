import React from "react";

import "../../Tiles/mobile.scss";

import SectionWithExtLink from "./SectionWithExtLink";
import SectionWithLink from "./SectionWithLink";
const SectionWithInfo = ({ section, settings, font_size }) => {
  const { data } = section;

  function renderButton() {
    switch (data) {
      case "external":
        return (
          <SectionWithLink
            section={section}
            key={section.section_id}
          ></SectionWithLink>
        );
        break;
      case "internal":
        return (
          <div style={{ paddingBottom: 10 }}>
            <SectionWithLink
              section={section}
              key={section.section_id}
            ></SectionWithLink>
          </div>
        );

        break;
      default:
        return <></>;
        break;
    }
  }

  return (
    <div
      style={{
        margin: 15,
        padding: 0,
        borderRadius: 10,
        backgroundColor: "#F5F5F5",
      }}
    >
      {section.image_url !== null ? (
        <div>
          <img
            style={{
              margin: 0,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              padding: 0,
              width: "100%",
            }}
            src={section.image_url}
          ></img>
        </div>
      ) : (
        <></>
      )}
      <div
        className="section_text"
        style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
        dangerouslySetInnerHTML={{ __html: section.text }}
      ></div>
      {renderButton()}
    </div>
  );
};

export default SectionWithInfo;
