import React from "react";
import { View, Image } from "react-native-web";
import _ from "lodash";
export default class SectionWithImage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { images, section, section_id, settings, radius } = this.props;
    var width = settings.device_width;
    console.log(section);

    let image = section.image_url;

    try {
      image = _.find(this.props.images, { hash: section.image_hash }).image_url;
    } catch (error) {}
    // try {
    //   image = _.find(this.props.images, { hash: section.image_hash }).image_url;
    // } catch (error) {}
    // if (images[section.image_hash].cached_image !== null){
    //   image = images[section.image_hash].cached_image
    // }
    var border_radius = 0;
    if (radius === true) {
      border_radius = 10;
    }
    var new_width = width;
    var border_radius = 0;
    if (radius === true) {
      border_radius = 12;
      new_width = new_width - 2;
    }
    console.log(image);

    var style = {
      width: new_width,
      aspectRatio: 16 / 9,
      marginBottom: 15,
    };

    if (section.image_style === "inline") {
      style = {
        width: width - 15 * 2,
        margin: 15,
        aspectRatio: 16 / 9,
        borderRadius: 20,
      };
    }
    if (!!section.image_url === false) {
      return (
        <div style={{ padding: 20, textAlign: "center", color: "red" }}>
          NO IMAGE SELECTED
        </div>
      );
    }
    return (
      <View
      // style={{
      //   borderTopLeftRadius: border_radius,
      //   borderTopRightRadius: border_radius,
      // }}
      >
        {/* <div>{JSON.stringify(section.image_hash)}</div> */}
        <Image
          style={style}
          source={{ uri: image }}
          key={section_id}
          resizeMode="contain"
        />
      </View>
    );
  }
}
// Please select your cancer pathway

// Select which cancer type are you currently receiving treatment for.

// Other
// Bladder
// Bowel
// Breast
// Gynaecological
// Haematological
// Head and Neck
// Kidney
// Lung
// Oesophagus/Stomach
// Pancreas/Gallbladder
// Prostate
// Skin
