import React from "react";
import {
  Text,
  View,
  TouchableWithoutFeedback,
  LayoutAnimation,
} from "react-native-web";
// import HTMLSegment from "./HTMLSegment";

import "../../Tiles/mobile.scss";

import SunriseIcon from "../../../../common/components/SunriseIcon";
import colours from "../../../../data/colours.json";

import SectionWithExtLink from "./SectionWithExtLink";
import SectionWithLink from "./SectionWithLink";

export default class SectionWithAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      expanded: false,
    };
    this.selectAccordion = this.selectAccordion.bind(this);
  }

  componentDidUpdate() {
    LayoutAnimation.easeInEaseOut();
  }

  renderDescription() {
    const key = this.props.font_size + this.props.section.id;

    if (this.state.expanded) {
      return (
        // <View style={{ backgroundColor: colours.very_light_grey }}>
        //   <HTMLSegment
        //     html={this.props.section.text}
        //     imagesMaxWidth={this.props.width}
        //     key={key}
        //     font_size={this.props.font_size}
        //   />
        //   <View style={{ marginTop: 15 }}>{this.renderButton()}</View>
        // </View>
        // <div>{this.props.section.text}</div>
        <div>
          <div
            className="section_text"
            style={{ padding: 10 }}
            dangerouslySetInnerHTML={{ __html: this.props.section.text }}
          ></div>
          <View style={{ marginTop: 15 }}>{this.renderButton()}</View>
        </div>
      );
    }
  }

  renderButton = () => {
    switch (this.props.section.data) {
      case "external":
        return <SectionWithLink section={this.props.section} />;
        return (
          <SectionWithExtLink
            section={this.props.section}
            // images={images}
            // settings={settings}
            key={this.props.section.section_id}
          ></SectionWithExtLink>
        );
        break;
      case "internal":
        return <SectionWithLink section={this.props.section} />;
        return (
          <SectionWithLink
            section={this.props.section}
            navigation={this.props.navigation}
            //images={images}
            key={this.props.section.section_id}
          ></SectionWithLink>
        );
        break;
      default:
        return <></>;
        break;
    }
  };

  selectAccordion() {
    console.log(this.props.section.text);
    if (this.state.expanded === false) {
      this.setState({ expanded: true });
    }
    if (this.state.expanded === true) {
      this.setState({ expanded: false });
    }
  }

  render() {
    // let bottomPad = 0;
    // let paddingSide = 10;
    let icon = "plus";
    const { title, text } = this.props.section;
    if (this.state.expanded === true) {
      // bottomPad = 8;
      // paddingSide = 0;
      icon = "minus";
    }

    return (
      <TouchableWithoutFeedback onPress={() => this.selectAccordion()}>
        <View
          style={{
            borderWidth: 1,
            marginTop: 5,
            marginLeft: 12,
            marginRight: 12,
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 5,
            borderRadius: 5,
            backgroundColor: "#F5F5F5",
            borderColor: "#F5F5F5",
          }}
        >
          <View
            style={{
              paddingLeft: 5,
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                paddingLeft: 15,
                paddingRight: 30,
                //fontFamily: "sansPro",
                color: "#0F9E97",
                flex: 1,
              }}
            >
              {title}
            </Text>
            <View
              style={{
                marginLeft: 5,
                padding: 5,
                marginRight: 5,
                justifyContent: "space-around",
                alignSelf: "center",
                backgroundColor: "#0F9E97",
                borderRadius: 50,
              }}
            >
              <SunriseIcon icon={icon} size={12} stroke={"white"} />
            </View>
          </View>
          {this.renderDescription()}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

// import React, { setState, useState, useEffect, forceUpdate } from "react";
// import { Icon, Accordion } from "semantic-ui-react";
// import SectionWithExtLink from "./SectionWithExtLink";
// import SectionWithLink from "./SectionWithLink";

// const SectionWithAccordion = ({ section, settings, font_size }) => {
//   const { title, destination, colour, icon, text, data } = section;
//   const [open, update_open] = useState(false);

//   function handleClick(e, titleProps) {
//     update_open(!open);
//   }

//   function renderButton() {
//     switch (data) {
//       case "external":
//         return (
//           <SectionWithExtLink
//             section={section}
//             settings={settings}
//             key={section.section_id}
//           ></SectionWithExtLink>
//         );
//         break;
//       case "internal":
//         return (
//           <SectionWithLink
//             section={section}
//             key={section.section_id}
//           ></SectionWithLink>
//         );

//         break;
//       default:
//         return <></>;
//         break;
//     }
//   }

//   return (
//     <div>
//       <Accordion styled style={{ margin: 5, width: 266 }}>
//         <Accordion.Title active={open} onClick={handleClick}>
//           <Icon name="dropdown" />
//           {section.title}
//         </Accordion.Title>
//         <Accordion.Content active={open} style={{ padding: 3 }}>
//           <div
//             style={{ padding: 10 }}
//             dangerouslySetInnerHTML={{ __html: section.text }}
//           ></div>
//           {renderButton()}
//         </Accordion.Content>
//       </Accordion>
//     </div>
//   );
// };

// export default SectionWithAccordion;
