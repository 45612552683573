import React from "react";
import { View, Image, StyleSheet } from "react-native-web";

const LandingImage = (props, image) => {
  console.log(JSON.stringify(props.image));

  const { imageStyle } = styles;

  return <Image style={imageStyle} source={{ uri: props.image }} />;
};

const styles = StyleSheet.create({
  imageStyle: {
    height: 100,
    margin: 20,
    resizeMode: "contain",
  },
});

export default LandingImage;
