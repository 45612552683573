import React from "react";
import { connect } from "react-redux";
import { Header, Divider, Message } from "semantic-ui-react";
import _ from "lodash";

import TileSection from "../common/tiles/TileSection";
import LandingImage from "../common/tiles/LandingImage";

// import { fetchTiles } from '../../../actions';
import MobileTileSegment from "./MobileTileSegment";
import MobileSectionSegment from "./MobileSectionSegment";

class MobileTiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { origin: props.origin, updatedSectons: null };
  }

  componentDidMount(page_id) {
    const updatedSections = _.filter(this.props.sections, {
      section_id: this.props.origin,
    });
    this.setState({ updatedSectons: updatedSections });
  }

  renderContent() {
    const trust = _.find(this.props.trusts, { trust_id: this.props.trust });
    if (this.state.origin === "tiles" && trust !== undefined) {
      return (
        <div>
          {trust.image_url ? (
            <LandingImage alt="mySunrise Logo" image={trust.image_url} />
          ) : (
            // <div className="mobileSunriseLogo">
            //   <img alt="mySunrise Logo" src={trust.image_url} />
            // </div>
            <div style={{ padding: 10 }}>
              <Message negative>
                <Message.Header>No Sunrise Logo</Message.Header>
                <p>Please upload a Sunrise logo in the trust section</p>
              </Message>
            </div>
          )}
          <div>{this.returnTiles()}</div>
          {trust.trust_image ? (
            <div className=" v">
              <img alt="mySunrise Logo" src={trust.trust_image} />
            </div>
          ) : (
            <div style={{ padding: 10 }}>
              <Message negative>
                <Message.Header>No Trust Logo</Message.Header>
                <p>Please upload a trust logo in the trust section</p>
              </Message>
            </div>
          )}
        </div>
      );
    } else if (this.state.origin === null && this.props.sections !== null) {
      const ordered_sections = _.sortBy(this.props.sections, ["index_number"]);
      return ordered_sections.map((section) => {
        return (
          <MobileSectionSegment
            key={section.timestamp}
            section={section}
            images={this.props.images}
          />
        );
      });
    }
    return (
      <div>
        <h3 style={{ textAlign: "center", marginTop: 100 }}>
          To see a preview,
          <br /> please select Edit Page
        </h3>
      </div>
    );
  }

  returnTiles() {
    const ordered_tiles = _.sortBy(this.props.tiles, ["index_number"]);
    return ordered_tiles.map((tile) => {
      //return <MobileTileSegment key={tile.tile_id} tile={tile} />;
      return <TileSection key={tile.tile_id} tile={tile} />;
    });
  }

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <div className="mobile">
          <div className="mobile_screen">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { trusts: state.trusts, images: state.images };
};

export default connect(mapStateToProps, {})(MobileTiles);
