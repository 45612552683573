import React from "react";
import "./mobile.scss";
import { Icon, Accordion } from "semantic-ui-react";

import SunriseIcon from "../../../common/components/SunriseIcon";

import SectionWithImage from "../common/sections/SectionWithImage";
import SectionWithCTA from "../common/sections/SectionWithCTA";
import SectionWithLink from "../common/sections/SectionWithLink";
import SectionWithInfo from "../common/sections/SectionWithInfo";
import SectionWithAccordion from "../common/sections/SectionWithAccordion";
import SectionWithCheckbox from "../common/sections/SectionWithCheckbox";

class MobileSectionSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: props.origin,
    };
  }

  switch() {
    const { section } = this.props;
    switch (section.type) {
      case "text":
        return (
          <div>
            <div
              className="section_text"
              dangerouslySetInnerHTML={{ __html: this.props.section.text }}
            ></div>
          </div>
        );
      case "accordion":
        return (
          <SectionWithAccordion
            font_size={18}
            settings={{ device_width: 300 }}
            section={this.props.section}
          />
        );
      case "image":
        return (
          <SectionWithImage
            section={this.props.section}
            section_id={this.props.section.id}
            settings={{ device_width: 300 }}
            images={this.props.images}
          />
        );

      case "link":
        return <SectionWithLink section={this.props.section} />;

      case "cta":
        return (
          <SectionWithCTA
            section={this.props.section}
            font_size={18}
            settings={{ device_width: 300 }}
          />
        );
      case "checkbox":
        return (
          <SectionWithCheckbox
            section={this.props.section}
            font_size={18}
            settings={{ device_width: 300 }}
          />
        );

      case "info":
        return (
          <SectionWithInfo
            font_size={18}
            settings={{ device_width: 300 }}
            section={this.props.section}
          />
        );
      case "ext_link":
        if (section.destination === "vimeo_embed") {
          return (
            <div className="vimeo_embed">
              <iframe
                title="section."
                src={section.ext_link}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          );
        } else {
          return <SectionWithLink section={this.props.section} />;
        }
      default:
        return <div>Nothing to render</div>;
    }
  }

  render() {
    return this.switch();
  }
}

export default MobileSectionSegment;
