import React from "react";
import { Text, View } from "react-native-web";
// import { FontAwesome } from '@expo/vector-icons';
//import { Title } from "../index";

import SectionWithExtLink from "./SectionWithExtLink";
import SectionWithLink from "./SectionWithLink";

const SectionWithCTA = ({ section, font_size, settings }) => {
  const { title, destination, colour, icon, text, data } = section;

  const Title = ({ children }) => {
    return <div style={{ color: "white", fontSize: 18 }}>{children}</div>;
  };

  function renderButton() {
    switch (data) {
      case "external":
        return (
          <SectionWithExtLink
            section={section}
            settings={settings}
            inverted={true}
            key={section.section_id}
          ></SectionWithExtLink>
        );
        break;
      case "internal":
        return (
          <SectionWithLink
            section={section}
            //navigation={navigation}
            inverted={true}
            key={section.section_id}
          ></SectionWithLink>
        );

        break;
      default:
        return <></>;
        break;
    }
  }

  return (
    <View
      style={{
        backgroundColor: colour,
        margin: 10,
        borderRadius: 10,
        padding: 10,
      }}
    >
      <View style={{ padding: 11 }}>
        <Title align={"left"} size={font_size + 6}>
          {title}
        </Title>
        <Text
          style={{
            fontSize: font_size,
            color: "#fff",
            //fontFamily: "sansPro",
            fontSize: 14,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {text}
        </Text>
      </View>
      {renderButton()}
    </View>
  );
};

export default SectionWithCTA;
