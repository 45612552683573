import React from "react";
import { Text, View, TouchableOpacity, Platform } from "react-native-web";
//import { selectNavigation } from "../../../functions/selectNavigation";
import SunriseIcon from "../../../../common/components/SunriseIcon";

const TileSection = ({ tile, navigation, images }) => {
  const { title, colour, icon, image_hash } = tile;
  const { headerContentStyle, thumbnailContainerStyle, headerTextStyle } =
    styles;

  return (
    <TouchableOpacity
      style={{
        backgroundColor: colour || "#0c9f96",
        flexDirection: "row",
        marginBottom: 9,
        marginLeft: 22,
        marginRight: 22,
        borderRadius: 10,
        padding: 6,
      }}
      //onPress={() => c(navigation, tile)}
    >
      <View style={thumbnailContainerStyle}>
        <SunriseIcon icon={icon} size={45} />
      </View>
      <View style={headerContentStyle}>
        <Text style={headerTextStyle}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = {
  thumbnailContainerStyle: {
    justifyContent: "center",
    marginLeft: 7,
    marginRight: 14,
    padding: 2,
  },
  thumbnailStyle: {
    height: 38,
    width: 38,
  },
  headerContentStyle: {
    flex: 1,
    justifyContent: "space-around",
  },
  headerTextStyle: {
    fontSize: 14,
    color: "#fff",
    fontFamily: "helvetica",
  },
  iconContentStyle: {
    marginLeft: 5,
    marginRight: 5,
    justifyContent: "space-around",
  },
  iconStyle: {
    width: 20,
  },
};

export default TileSection;
